import React, { useState, useEffect } from 'react';
import { useToken } from 'contexts/TokenContext';
interface PaydunyaHookProps {
  reference: string;
}

const usePaydunya = (reference) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [paymentLinkResponse, setPaymentLinkResponse] = useState<any>({});

  useEffect(() => {
    const fetchPaymentLink = async () => {
      try {
        const headers: any = {
          'Content-Type': 'application/json',
          'X-API-KEY': process.env.REACT_APP_KZ_PAYMENT_API_KEY,
        };
        const response = await fetch(
          `${process.env.REACT_APP_KZ_PAYMENT_URL}/invoices/payment_link/${reference}`,
          {
            method: 'POST',
            headers,
            body: JSON.stringify({ reference }),
          }
        );
        if (response.status === 201) {
          setIsSuccess(true);
          setIsLoading(false);
          const data = await response.json();
          setPaymentLinkResponse(data);
        }
      } catch (error: any) {
        setIsError(true);
        setErrorMessage(error?.response?.data?.message);

        setIsLoading(false);
      }
    };
    setTimeout(() => {
      fetchPaymentLink();
    }, 1000);
  }, [reference]);

  return {
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    paymentLinkResponse,
  };
};

export default usePaydunya;
