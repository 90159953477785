import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Button,
  VStack,
  Box,
  Stack,
  useMediaQuery,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import { AiOutlineCreditCard, AiOutlineDownload } from 'react-icons/ai';
import FullPageLoader from '../components/Loaders/Fullpage.loader';
import InvoiceContent from '../components/Invoice/Content';
import InvoiceFooter from '../components/Invoice/Footer';
import useAxios from 'hooks/useAxios';
import InvoiceContentMobile from '../components/Invoice/ContentMobile';
import { downloadFile } from 'shared/utils';
import usePaydunya from 'hooks/usePaydunya';
import useFlutterwavePayment from 'hooks/useFlutterwavePayment';
import useFetchUserCountryCode from 'hooks/useFetchUserCountryCode';

import PaymentMethodModal from '../components/Modal/PaymentMethodModal';

import { Trans } from '@lingui/macro';


const Invoice: React.FC<{}> = (): JSX.Element => {
  const toast = useToast();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const query: any = useParams();
  const [renderModal, setRenderModal] = useState<boolean>(false);
  const { response, loading, error } = useAxios({
    method: 'POST',
    url: `/check_token/`,
    data: {
      token: query.token,
    },
  });
  const countryCode = useFetchUserCountryCode();
  const [isPaymentProcessing, setIsPaymentProcessing] =
    useState<boolean>(false);

  const setPaymentProcessing = (status: boolean) => {
    setIsPaymentProcessing(status);
  };
  const { handleFlutterPayment, closePaymentModal } = useFlutterwavePayment(
    response?.data
  );

  const [isLargerThan960] = useMediaQuery('(min-width: 960px)');
  const {
    isLoading,
    isError: isPaymentError,
    isSuccess: isPaymentSuccess,
    errorMessage,
    paymentLinkResponse,
  } = usePaydunya(response?.data?.invoice?.id);
  useEffect(() => {
    if (renderModal) {
      onOpen();
    } else {
      onClose();
    }
  }, [renderModal]);

  const handlePayment = () => {
    setRenderModal(true);
    setIsPaymentProcessing(true);
  };

  const handlePaymentNotification = (data: any, type?: string) => {
    if (type === 'success') {
      toast({
        title: 'Notification de Paiement',
        description: data,
        status: 'error',
        position: 'bottom',
        duration: 10000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Notification de Paiement',
        description: data,
        status: 'error',
        position: 'bottom',
        duration: 20000,
        isClosable: true,
      });
    }
  };

  const handleFileDownload = (e) => {
    e.preventDefault();
    const file = '/api/invoice/download/';
    downloadFile(file, 'application/pdf');
    toast({
      description: 'Le fichier a été téléchargé avec succès',
      status: 'success',
      position: 'bottom',
      duration: 10000,
      isClosable: true,
    });
  };

  if (error) {
    history.push('/TokenError');
  }

  if (loading) {
    return <FullPageLoader />;
  }
  return (
    <>
      <Flex
        bg="white"
        p="15px 12%"
        justify="space-between"
        color="gray.800"
        pos={'fixed'}
        w="full"
        zIndex="99999"
        borderBottom="2px solid #eaeaea"
        height={100}
      >
        {isLargerThan960 ? (
          <>
            <Box
              textStyle="brandHeading"
              textAlign={'left'}
              justifyContent={'center'}
            >
              <Stack spacing={0}>
                <Text fontWeight={'bold'} textTransform={'capitalize'}>
                  {response?.data.company?.name}
                </Text>
                <Text>
                  <Text as={'span'}>
                    <Trans>Référence</Trans> :{response?.data.invoice?.reference_code}{' '}
                  </Text>{' '}
                    <Trans>Montant due</Trans>: {response?.data.totals?.in_number}{' '}{response?.data?.currency}
                </Text>
              </Stack>
            </Box>
            <Flex justify="space-between" mt={2}>
              <Button
                mr={2}
                leftIcon={<AiOutlineDownload />}
                height={37}
                onClick={handleFileDownload}
              >
                <Trans>Télécharger le PDF</Trans>
              </Button>
              {!response?.data.invoice?.payed_at && (
                <Button
                  leftIcon={<AiOutlineCreditCard />}
                  bg={'#078942'}
                  disabled={isPaymentProcessing}
                  color={'white'}
                  height={37}
                  onClick={handlePayment}
                >
                  <Trans>Payer la facture</Trans>
                </Button>
              )}
            </Flex>
          </>
        ) : (
          <>
            <VStack
              textAlign={'center'}
              justifyContent={'center'}
              justifyItems={'center'}
              m={'auto'}
            >
              <Text fontWeight={'bold'}>{response?.data.company?.name}</Text>
              <Text fontWeight={'bold'}>{response?.data.totals?.in_number}{' '}{response?.data?.currency}</Text>
            </VStack>
          </>
        )}
      </Flex>

      {isLargerThan960 ? (
        <InvoiceContent token={query.token} />
      ) : (
        <InvoiceContentMobile
          datas={response?.data}
          onPaymentClick={handlePayment}
        />
      )}

      {/* {renderModal && (
        <ModalPayment
          isOpen={isOpen}
          onClose={onClose}
          isLoading={isLoading}
          isPaymentError={isPaymentError}
          isPaymentSuccess={isPaymentSuccess}
          invoice={response?.data.invoice}
          errorMessage={errorMessage}
          paymentLinkResponse={paymentLinkResponse}
          onRenderModal={setRenderModal}
          onAlertPaymentMessage={handlePaymentNotification}
          countryCode={countryCode}
          onFlutterwavePayment={handleFlutterPayment}
          onFlutterwavePaymentCloseModal={closePaymentModal}
          setPaymentProcessing={setPaymentProcessing}
        />
      )} */}
      
      {renderModal && (
        <PaymentMethodModal
          isOpen={isOpen}
          onClose={onClose}
          invoiceToken={query.token}
          invoiceReference={response?.data.invoice?.reference_code}
        />
      )}

      <InvoiceFooter />
    </>
  );
};

export default Invoice;
