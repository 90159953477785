// hook to get user country code from ip address using ipapi.co
import React from 'react';
const useFetchUserCountryCode = () => {
  const [countryCode, setCountryCode] = React.useState('');
  React.useEffect(() => {
    const fetchCountryCode = async () => {
      const response = await fetch('https://ipapi.co/json');
      const data = await response.json();
      setCountryCode(data.country_code);
    };
    fetchCountryCode();
  }, []);
  return countryCode;
};

export default useFetchUserCountryCode;
