import React, { FC } from 'react';

import { Flex, Box, Link, HStack, Text, Image } from '@chakra-ui/react';
import Logo from 'assets/logo.svg';
import { Trans } from '@lingui/macro';
interface InvoiceFooterProps {}

const InvoiceFooter: FC<InvoiceFooterProps> = (props) => {
  const currentYear = new Date().getFullYear();

  return (
    <Flex justifyContent={'center'}>
      <Box
        my={10}
        color="#A0A4A7"
        justifyContent={'center'}
        textAlign={'center'}
      >
        <HStack spacing={4} ml={'12%'} fontSize={18}>
          <Text fontWeight={'semibold'}>Powered by</Text>
          <Image src={Logo} width={'100px'} />
        </HStack>

        <HStack spacing={5} pt={2} fontSize={14}>
          <Text>Kudizy {currentYear}</Text>
          <Link href=""><Trans>Confidentialité</Trans></Link>
          <Link href=""><Trans>Securité</Trans></Link>
        </HStack>
      </Box>
    </Flex>
  );
};

export default InvoiceFooter;
