import { useLocation } from 'react-router-dom';
import React from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

const useFlutterwavePayment = (data): any => {

  const flw_key = process.env.REACT_APP_FLW_PUBLIC_KEY;
  const payment_options = process.env?.REACT_APP_FLW_PAYMENT_OPTIONS;
  const flw_fees = process.env?.REACT_APP_FLW_FEES
    ? +process.env?.REACT_APP_FLW_FEES
    : 0.035;
  
  const amountToPay = data?.totals?.in_number;
  const totalAmount = data?.take_fees_in_charge
  ? amountToPay 
  : amountToPay * (1 + flw_fees);

  const config: any = {
    public_key: flw_key?.trim(),
    tx_ref: `kud-inv-${data?.invoice?.reference_code}#${data?.invoice?.id}#${data?.company?.id}`,
    amount: amountToPay,
    currency: data?.currency || 'XOF',
    payment_options: payment_options || 'card,mobilemoney,ussd',
    customer: {
      email: data?.customer?.email,
    },
    customizations: {
      title: data?.company?.name,
      description: 'Paiement de facture',
      logo: data?.company?.logo,
    },
    meta: {
      company_id: data?.company?.id,
      user_id: data?.invoice?.user,
      invoice_id: data?.invoice?.invoice_id,
    }
  };

  const handleFlutterPayment = useFlutterwave(config);

  return { handleFlutterPayment, closePaymentModal };
};

export default useFlutterwavePayment;
