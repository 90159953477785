import React from "react";
import { VStack, Stack, Text, Image } from "@chakra-ui/react";
import SuccessMark from 'assets/success-mark.png';
import { Trans } from "@lingui/macro";

interface PaymentSuccessProps {
  invoiceReference: string;
}

const InvoicePaymentSuccess: React.FC<PaymentSuccessProps> = ({ invoiceReference }) => {
  return (
    <VStack width="100%" mt={{ base: 20, md: 24 }}>
      <Stack textAlign="center">
        <Image src={SuccessMark} alt="success-mark" height={{base: 32, md:48}} width={{base: 32, md:48}} />
      </Stack>
      <Text pt={2} fontSize={{base: 24, md:32}} color="green.400" fontWeight="semibold">
        <Trans>Facture payée avec succès !</Trans>
      </Text>
      <Text fontSize={20} color="gray.500">
        <Trans>Transaction Numéro</Trans>: {invoiceReference}
      </Text>
    </VStack>
  );
};

export default InvoicePaymentSuccess;
